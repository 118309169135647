import { useState, useEffect } from 'react';

//对手机版页面进行优化
const useResponsiveCollapse = () => {
    const [collapsed, setCollapsed] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 768) {
                setCollapsed(true);
            } else {
                setCollapsed(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Initialize state based on current window size

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return [collapsed, setCollapsed];
};

export default useResponsiveCollapse;