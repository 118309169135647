import React from "react";
import MusicExec from "./components/MusicExec";
import {Card} from "antd";

const LiveReview = () => {
    return (<>
            <div>
                <div>
                    <Card
                        title="直播切片辅助"
                    >
                        <MusicExec/>
                    </Card>
                </div>
            </div>
        </>)
}
export default LiveReview;