import React, {useState} from 'react';

// import 'antd/dist/antd.css';  // 引入 Ant Design 样式


import {Menu, Layout, Button, Drawer} from 'antd';
import {MenuOutlined} from '@ant-design/icons';
import {BrowserRouter as Router, Route, Routes, Link} from 'react-router-dom';
import Home from "./pages/home/home";
import ToMusic from "./pages/tools/toMusic/ToMusic";
import LiveReview from "./pages/tools/liveReview/LiveReview";
import Login from "./pages/tools/login/Login";
import useResponsiveCollapse from "./features/hooks/useResponsiveCollapse";


const {Content, Sider} = Layout;

const items = [{
    key: '1', label: <Link to="/">主页</Link>,
}, {
    key: '2', label: <Link to="/tools/toMusic">音频转换</Link>,
}, {
    key: '3', label: <Link to="/tools/liveReview">切片辅助</Link>,
}, {
    key: '4', label: <Link to="/login">登录</Link>,
},
];


function App() {
    const [visible, setVisible] = useState(false);
    const [collapsed] = useResponsiveCollapse();

    const toggleDrawer = () => {
        setVisible(!visible);
    };

    return (
        (<Router>
            <Layout style={{minHeight: '100vh', background: '#f0f2f5'}}>
                {collapsed ? (<>
                        <Button
                            type="primary"
                            onClick={toggleDrawer}
                            style={{margin: 16}}
                            icon={<MenuOutlined/>}
                        />
                        <Drawer
                            width="75%"
                            title="Menu"
                            placement="left"
                            onClose={toggleDrawer}
                            open={visible}
                        >
                            <Menu
                                mode="inline"
                                items={items}
                                onClick={toggleDrawer}
                            />
                        </Drawer>
                    </>) :
                    (<Sider
                        style={{background: '#fff'}}
                    >
                        <Menu
                            defaultSelectedKeys={['1']}
                            mode="inline"
                            items={items}
                        />
                    </Sider>)}
                <Layout style={{background: '#f0f2f5'}}>
                    <Content style={{
                        padding: '24px',
                        background: 'linear-gradient(lightblue 10%, lightskyblue 40%, DodgerBlue 70%)',
                        minHeight: '100vh'
                    }}>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/tools/toMusic" element={<ToMusic/>}/>
                            <Route path="/tools/liveReview" element={<LiveReview/>}/>
                            <Route path="/login" element={<Login/>}/>
                        </Routes>
                    </Content>
                </Layout>
            </Layout>
        </Router>)
    );
}

export default App;