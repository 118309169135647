import {Alert, Button, Card, Divider, message, Typography} from "antd";
import React, {useEffect, useState} from "react";
import {BASE_URL} from "../../../../utils/url";
import {FLVPlayer} from "./VideoPlayer";
import {MP4Player} from "./VideoPlayer";
import axios from "axios";
import TimeSelector from "./TimeSelector";
import dayjs from "dayjs";
import useLoading from "../../../../features/hooks/useLoading";

const DividedMusicDownload = ({musicName}, startTime = '00:00:00', endTime_default = '00:01:00') => {
    const [playName, setPlayName] = useState(musicName)
    const format = 'HH:mm:ss';
    const [endTime, setEndTime] = useState(endTime_default);
    const endTimeDayjs = dayjs(endTime, format);
    const [timeRange, setTimeRange] = useState([dayjs().startOf('day'), endTimeDayjs]);
    const [sliderValue, setSliderValue] = useState([0, endTimeDayjs.diff(dayjs().startOf('day'), 'second')]);
    const [isPreview, setIsPreview] = useState(false)

    const {loadings, enterLoading, exitLoading} = useLoading(2);

    useEffect(() => {
        // 当 endTime 改变时，更新 timeRange
        const endTimeDayjs = dayjs(endTime, format);
        setTimeRange([dayjs().startOf('day'), endTimeDayjs]);
        setSliderValue([0, endTimeDayjs.diff(dayjs().startOf('day'), 'second')]);
    }, [endTime, musicName]); // 监听 endTime 的变化

    const handleMusicDownload = async () => {
        try {
            const [startTime, endTime] = timeRange;
            const differenceInMinutes = endTime.diff(startTime, 'minute');
            if (playName === musicName) {
                message.warning('请先选择范围并预览');
            } else if (differenceInMinutes > 10) {
                message.warning('请选择10分钟内的范围');
            } else {
                enterLoading(1);
                const response = await axios.post(BASE_URL + '/api/divided_download/', {playName}, {
                    responseType: 'blob',
                    timeout: 60000
                });
                console.log(response)
                const contentDisposition = response.headers['content-disposition'];
                let filename = 'download';
                if (contentDisposition) {
                    const filenameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/i);
                    if (filenameMatch != null) {
                        filename = decodeURIComponent(filenameMatch[1]);
                    }
                }
                const blob = new Blob([response.data]);
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();

                // 清理
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);

                return Promise.resolve();
            }
        } catch (error) {
            console.error('Download failed:', error);
            message.error(error)
            return Promise.reject(error);
        } finally {
            exitLoading(1);
        }
    }
    const handleMusicPreview = async () => {
        console.log('触发预览，时间段：', timeRange)
        try {
            const [startTime, endTime] = timeRange;
            const differenceInMinutes = endTime.diff(startTime, 'minute');
            if (differenceInMinutes > 10) {
                message.warning('暂不支持超过10分钟的片段')
            } else {
                enterLoading(0);
                message.warning('正在处理，请稍后');
                const response = await axios.post(BASE_URL + '/api/preview-video/', {
                    fileName: musicName,
                    divideTime: timeRange.map(time => time.format(format))
                });
                console.log(response);
                const data = response.data;
                const videoName = data['video_name'] + '.mp4';
                const videoPath = 'divide/' + videoName;
                setPlayName(videoPath);
                setIsPreview(true);
                message.success('ok');
            }
        } catch (error) {
            console.log(error);
            message.error('error');
            setIsPreview(false);
        } finally {
            exitLoading(0);
        }
    }
    const onReset = () => {
        setTimeRange([dayjs().startOf('day'), endTimeDayjs]);
        setSliderValue([0, endTimeDayjs.diff(dayjs().startOf('day'), 'second')]);
        setIsPreview(false);
    }
    const videoUrl = BASE_URL + '/api/video-stream/'
    return (
        <>
            <Card>
                <Typography.Title level={3}>快速切片</Typography.Title>
                <Alert showIcon description={'请先预览后再下载，暂时只支持10分钟内片段'} type={"info"}
                       style={{marginBottom: '10px'}}/>
                <div>
                    <TimeSelector timeRange={timeRange} setTimeRange={setTimeRange} sliderValue={sliderValue}
                                  setSliderValue={setSliderValue} endTime={endTime}/>
                    <Button style={{marginRight: '10px'}} type={"primary"} loading={loadings[0]}
                            onClick={handleMusicPreview}>
                        预览
                    </Button>
                    <Button style={{marginRight: '10px'}} type={"primary"} loading={loadings[1]}
                            onClick={handleMusicDownload}>
                        下载
                    </Button>
                    <Button type={"primary"} onClick={onReset}>重置</Button>
                    <Divider/>
                    <FLVPlayer url={videoUrl + '?file=' + musicName} setEndTime={setEndTime}/>
                    <Divider/>
                    <div>
                        {isPreview &&
                            <div>
                                <Typography.Title level={5}>切片预览</Typography.Title>
                                <MP4Player url={videoUrl + '?file=' + playName}/>
                            </div>
                        }
                    </div>
                </div>
            </Card>
        </>
    )
}

export default DividedMusicDownload;