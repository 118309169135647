import React, {useState} from 'react';
import {Button, Input, message} from "antd";
import {useDispatch} from "react-redux";
import urlStore from "../../../../features/counter/urlSlice";
import DownloadVideo from "./FileDownload";
import useLoading from "../../../../features/hooks/useLoading";

function VideoDownloader() {
    const [url, setUrl] = useState('');
    const { loadings, enterLoading, exitLoading } = useLoading(1);
    const {setDownloadUrl} = urlStore.actions
    const dispatch = useDispatch();

    const handleDownload = () => {
        const urlRegex = /^(https?:\/\/)?(www\.)?(bilibili\.com\/video|b23\.tv)/;

        if (urlRegex.test(url)) {
            enterLoading(0);
            DownloadVideo(url).then(() => {
                message.success('成功')
            }).catch(e => {
                console.log(e);
            }).finally(() => {
                exitLoading(0);
            })
        } else {
            message.error('请输入正确的链接');
        }
    };
    const handleInputChange = (e) => {
        setUrl(e.target.value);
        dispatch(setDownloadUrl(e.target.value))
    }

    return (<div>
        <Input
            type="text"
            value={url}
            onChange={(e) => handleInputChange(e)}
            placeholder="bilibili.com/...或b23.tv/..."
        />
        <Button type={"primary"} style={{marginTop: '15px'}} loading={loadings[0]} onClick={handleDownload}>
            下载
        </Button>
    </div>);
}

export default VideoDownloader;