import React, {useState} from 'react';
import {Card, Divider, Button, message, Upload, Result, Modal, Col, Row} from "antd";
import {UploadOutlined, VideoCameraAddOutlined} from "@ant-design/icons";
import ServerFileSelector from "./ServerFileSelector";
import {BASE_URL} from "../../../../utils/url";
import axios from "axios";
import ItemSelector from "./ItemSelector";
import DividedMusicDownload from "./DividedMusicDownload";
import useResponsiveCollapse from "../../../../features/hooks/useResponsiveCollapse";
import useLoading from "../../../../features/hooks/useLoading";
import {secondsToHoursMinutesSeconds} from "../../../../utils/timeUtils";

function MusicExec() {
    const [open, setOpen] = useState(false);
    const [intervals, setIntervals] = useState([]);
    const [ok, setOk] = useState(false);
    const [method, setMethod] = useState(0);
    const [recognizeResult, setRecognizeResult] = useState([]);
    const [currentLiver, setCurrentLiver] = useState('雨纪_Ameki');
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [recognizeOK, setRecognizeOK] = useState(false);
    const [collapsed] = useResponsiveCollapse();
    const {loadings, enterLoading, exitLoading} = useLoading(1);

    const handleUpload = async (options) => {
        const {onSuccess, file,} = options;
        const formData = new FormData();
        formData.append('file', file);
        // You can use any AJAX library you like
        try {
            const response = await fetch(BASE_URL + '/api/upload-xml/', {
                method: 'POST', body: formData,
            });
            const data = await response.json();
            console.log('上传成功,返回数据：', data);
            onSuccess();
            message.success('upload successfully.');
            setIntervals(JSON.parse(data.data));
            setOk(true);
            setMethod(1);
            return response;
        } catch (error) {
            message.error('上传失败');
            console.log(error);
        } finally {
        }
    };
    const showModal = () => {
        setOpen(true);
    };
    const [selectedFile, setSelectedFile] = useState(null);
    const handleFileSelect = (file) => {
        setSelectedFile(file);
        setMethod(2);
    };
    const handleOk = async () => {
        console.log(selectedFile);
        setConfirmLoading(true);
        setRecognizeOK(false);
        if (selectedFile) {
            try {
                const response = await fetch(BASE_URL + '/api/handle_local_xml/', {
                    method: 'POST', headers: {
                        'Content-Type': 'application/json',
                    }, body: JSON.stringify({file_name: selectedFile}),
                });
                const data = await response.json();
                setIntervals(JSON.parse(data.data));
                setOk(true);
                setConfirmLoading(false);
                setOpen(false);
                console.log('处理成功，返回数据：', data);
                message.success('文件处理成功');
                return response;
            } catch (error) {
                message.error('处理失败');
                console.log(error);
            } finally {
                setConfirmLoading(false);
            }
        }
    }
    const handleCancel = () => {
        setConfirmLoading(false);
        setOpen(false);
    };
    const handleMusicRecognize = async () => {
        try {
            enterLoading(0);
            message.warning('首次处理大约需要一分钟（取决于歌曲多少），请稍等')
            console.log(selectedFile)
            const response = await axios.post(BASE_URL + '/api/recognize-music/', {
                fileName: {selectedFile}, intervals: {intervals}
            })
            console.log(response.data.result);
            setRecognizeResult(response.data.result);
            setRecognizeOK(true);
            message.success('识别处理成功');
        } catch (e) {
            message.error('出现错误');
            console.log(e);
        } finally {
            exitLoading(0);
        }
    }
    return (<>
        <ItemSelector currentValue={currentLiver} setCurrentValue={setCurrentLiver}/>
        <div style={{marginTop: "15px"}}>
            <div>
                <Upload
                    maxCount={1}
                    accept={".xml"}
                    customRequest={handleUpload}
                >
                    <Button type={"primary"} icon={<UploadOutlined/>}>来自xml文件</Button>
                </Upload>
                {currentLiver === '雨纪_Ameki' &&
                    <Button type={"primary"} icon={<VideoCameraAddOutlined/>} style={{marginTop: "15px"}}
                            onClick={showModal}>来自录播</Button>}
                {(method === 2 && ok && currentLiver === '雨纪_Ameki') && <Button
                    style={{marginLeft: '10px'}}
                    type={'primary'}
                    onClick={handleMusicRecognize}
                    loading={loadings[0]}
                >
                    歌曲识别(ACRCloud)
                </Button>}
                <Modal
                    title="选择录播文件"
                    open={open}
                    onOk={handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={handleCancel}
                >
                    <div>
                        <ServerFileSelector onFileSelect={(selectedFile) => handleFileSelect(selectedFile)}/>
                    </div>
                </Modal>
                <Divider/>
                {ok && ((currentLiver === '雨纪_Ameki' && method === 2) ? (collapsed ? (<>
                            <Result
                                status="success"
                                title="处理完成"
                                extra={<Card>
                                    {intervals.map(([start, end], index) => (<div key={index}>
                                        开始时间: {secondsToHoursMinutesSeconds(start)},
                                        结束时间: {secondsToHoursMinutesSeconds(end)}
                                        {recognizeOK && <div>{recognizeResult[index]}</div>}
                                        <Divider/>
                                    </div>))}

                                </Card>}
                            />
                            <DividedMusicDownload musicName={selectedFile}/>
                        </>) : (<>
                            <Row>
                                <Col span={12}>
                                    <Result
                                        status="success"
                                        title="处理完成"
                                        extra={<Card>
                                            {intervals.map(([start, end], index) => (<div key={index}>
                                                开始时间: {secondsToHoursMinutesSeconds(start)},
                                                结束时间: {secondsToHoursMinutesSeconds(end)}
                                                {recognizeOK && <div>{recognizeResult[index]}</div>}
                                                <Divider/>
                                            </div>))}

                                        </Card>}
                                    />
                                </Col>
                                <Col span={12}>
                                    <DividedMusicDownload musicName={selectedFile}/>
                                </Col>
                            </Row>
                        </>)) : (<Result
                        status="success"
                        title="处理完成"
                        extra={<Card>
                            {intervals.map(([start, end], index) => (<div key={index}>
                                开始时间: {secondsToHoursMinutesSeconds(start)},
                                结束时间: {secondsToHoursMinutesSeconds(end)}
                                {recognizeOK && <div>{recognizeResult[index]}</div>}
                                <Divider/>
                            </div>))}
                        </Card>}
                    />))}
            </div>
        </div>
    </>);
}

export default MusicExec;