import {useEffect, useRef} from 'react';
import Artplayer from 'artplayer';
import flvjs from "flv.js";
import {secondsToHMS} from "../../../../utils/timeUtils";

export default function Player({option, getInstance, setEndTime, ...rest}) {
    const artRef = useRef();

    function playFlv(video, url, art) {
        if (flvjs.isSupported()) {
            if (art.flv) art.flv.destroy();
            const flv = flvjs.createPlayer({type: 'flv', url: url});
            flv.attachMediaElement(video);
            flv.load();
            art.flv = flv;
            art.on('destroy', () => flv.destroy());
        } else {
            art.notice.show = 'Unsupported playback format: flv';
        }
    }

    useEffect(() => {
        const art = new Artplayer({
            ...option, container: artRef.current, type: 'flv', customType: {
                flv: playFlv,
            }, autoSize: true, fullscreen: true,
        });
        const handleLoadedMetadata = () => {
            // 转换时间格式
            const time = secondsToHMS(art.duration);
            setEndTime(time);
        }
        //设置时长
        art.on('video:loadedmetadata', () => {
            handleLoadedMetadata()
        });
        //修复可能因为音频流不标准导致的seeking失败
        const handleSeeking = () => {
            console.log('seeking')
            const nowState = art.attr('readyState');
            if (nowState === 1) {
                setTimeout(() => {
                    handleVideoCurrentTime();
                }, 2000)
            }
        }

        const handleVideoCurrentTime = () => {
            const nowState = art.attr('readyState');
            if (nowState === 1) {
                console.log('出现nowState=1,尝试修复')
                let buffered = art.attr('buffered');
                if (buffered.length > 0) {
                    let end = buffered.end(0)
                    if (end - art.attr('currentTime') > 0.15) {
                        art.attr('currentTime', art.attr('currentTime') + 0.1)
                    }
                }
            }
        }

        art.on('video:seeking', () => {
            setTimeout(() => {
                handleSeeking();
            }, 2000)

        });

        if (getInstance && typeof getInstance === 'function') {
            getInstance(art);
        }

        return () => {
            if (art && art.destroy) {
                art.destroy(false);
            }
        };
    }, [option.url]);

    return (<>
        <div ref={artRef} {...rest}></div>
    </>);
}