import {Alert, Button, Divider, Form, Input, message, Modal, Select, Space} from "antd";
import React, {useRef, useState} from "react";
import {CloseOutlined, PlusOutlined} from "@ant-design/icons";

const ItemSelector = ({currentValue, setCurrentValue}) => {
    const {Option} = Select;

    const [name, setName] = useState('');
    const [items, setItems] = useState(['雨纪_Ameki',]);
    const [index, setIndex] = useState(0);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const inputRef = useRef(null);

    const handleChange = (value) => {
        setCurrentValue(value);
    };
    const onNameChange = (event) => {
        setName(event.target.value);
    };
    const addItem = (e) => {
        e.preventDefault();
        if (name === '') {
            message.warning('请输入预设名称')
        } else if (items.includes(name)) {
            message.warning('该预设已存在');
        } else {
            showModal();
        }
    };
    const deleteItem = (e, index) => {
        e.stopPropagation();
        e.preventDefault();
        const updated = [...items];
        updated.splice(index, 1);
        setItems(updated);
        setCurrentValue('雨纪_Ameki')
    };
    const onDropdownVisibleChange = () => {
        setDropdownVisible(!dropdownVisible);
    }
    //对话框
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setItems([...items, name || `新预设 ${index}`]);
        setIndex(index + 1);
        setName('');
        setTimeout(() => {
            inputRef.current?.focus();
        }, 0);
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const [form] = Form.useForm();

    return (
        <>
            {/*预设选择部分*/}
            <div>
                <Alert message={'目前仅雨纪_Ameki支持录播选择'} type={'info'} showIcon/>
                <Divider/>
                <Select
                    style={{
                        width: 300,
                    }}
                    placeholder="预设"
                    value={currentValue}
                    onDropdownVisibleChange={onDropdownVisibleChange}
                    onChange={handleChange}
                    dropdownRender={(menu) => (<>
                        {menu}
                        <Divider
                            style={{
                                margin: '8px 0',
                            }}
                        />
                        <Space
                            style={{
                                padding: '0 8px 4px',
                            }}
                        >
                            <Input
                                placeholder="新预设"
                                ref={inputRef}
                                value={name}
                                onChange={onNameChange}
                            />
                            <Button type="text" icon={<PlusOutlined/>} onClick={addItem}>
                                添加
                            </Button>

                        </Space>
                    </>)}
                >
                    {items.map((item, index) => (<Option key={item}>
                        <div
                            style={{
                                display: "flex", justifyContent: "space-between", alignItems: "center"
                            }}
                        >
                            <div>{item}</div>
                            {item !== '雨纪_Ameki' && dropdownVisible && currentValue !== item &&
                                <Button onClick={(e) => deleteItem(e, index)} danger size="small" shape="circle"
                                        icon={<CloseOutlined/>}>
                                </Button>}
                        </div>
                    </Option>))}
                </Select>
                <Divider/>
                <Modal title="预设信息" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} footer={null}>
                    <Form
                        layout="vertical"
                        form={form}
                        initialValues={{
                            layout: "vertical",
                        }}
                        style={{
                            maxWidth: "vertical" === 'inline' ? 'none' : 600,
                            marginTop:'15px'
                        }}
                    >
                        <Form.Item label="Field A">
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item label="Field B">
                            <Input placeholder="input placeholder" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary">Submit</Button>
                        </Form.Item>
                    </Form>
                </Modal>
            </div>
        </>
    )
}

export default ItemSelector;