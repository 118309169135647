import axios from 'axios';
import {message} from "antd";
import {BASE_URL} from "../../../../utils/url";

const DownloadVideo = async (userInput) => {
    try {
        const response = await axios.post(BASE_URL + '/api/download-video/', {userInput}, {
            responseType: 'blob',
            timeout: 60000
        });
        console.log(response)
        const downloadId = response.headers['x-download-id'];
        const contentDisposition = response.headers['content-disposition'];
        let filename = 'download';
        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/i);
            if (filenameMatch != null) {
                filename = decodeURIComponent(filenameMatch[1]);
            }
        }
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();

        // 清理
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);

        await axios.post(BASE_URL + '/api/complete-download/', {downloadId});

        return Promise.resolve();
    } catch (error) {
        console.error('Download failed:', error);
        message.error(error)
        return Promise.reject(error);
    }
};

export default DownloadVideo;