import {Button, Card, Divider, Modal} from 'antd';
import React, {useState} from 'react';
import InputForm from "./components/InputForm";
import UploadToNeteaseCloud from "./components/UploadToNeteaseCloud";

const ToMusic = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const tabList = [{
        key: 'tab1', tab: 'b站链接',
    }, {
        key: 'tab2', tab: '上传文件',
    },];
    const contentList = {
        tab1: <>
            <div>
                <h3>链接：</h3>
                <InputForm/>
                <div style={{marginTop:'10px'}}>
                    <Button type="primary" onClick={showModal}>
                        上传到网易云
                    </Button>
                    <Modal title="上传到网易云" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                        <UploadToNeteaseCloud/>
                    </Modal>
                </div>

            </div>
            <Divider/>
        </>, tab2: <>
            <div>
                施工中
            </div>
        </>,
    };
    const [activeTabKey1, setActiveTabKey1] = useState('tab1');
    const onTab1Change = (key) => {
        setActiveTabKey1(key);
    };
    return (<>
        <Card
            style={{
                width: '100%',
            }}
            title="音频转换"
            tabList={tabList}
            activeTabKey={activeTabKey1}
            onTabChange={(key) => {
                onTab1Change(key);
            }}
        >
            {contentList[activeTabKey1]}
        </Card>
    </>);
};

export default ToMusic;