import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {DatePicker, Divider, message, Radio} from "antd";
import {BASE_URL} from "../../../../utils/url";

const ServerFileSelector =( { onFileSelect }) => {
    const [files, setFiles] = useState([]);
    const [selectedFile, setSelectedFile] = useState(null);

    useEffect(() => {
        onFileSelect(selectedFile);
    }, [selectedFile, onFileSelect]);

    useEffect(() => {
        axios.get(BASE_URL + '/api/get-server-files/')
            .then(response => {
                setFiles(response.data.files);
            })
            .catch(error => {
                console.error('Error fetching server files:', error);
                message.error('Error fetching server files');
            });
    }, []);

    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    const onSelectedFile = (e) => {
        console.log('radio checked', e.target.value);
        setSelectedFile(e.target.value);
    };

    const sortedFiles = files.sort((a, b) => {
        // 提取日期部分并转为数字进行比较
        const dateA = parseInt(a.split('-')[2], 10);
        const dateB = parseInt(b.split('-')[2], 10);
        return dateA - dateB;
    });

    return (
        <div>
            <DatePicker onChange={onChange} picker="month"/>
            <Divider/>
            <Radio.Group onChange={onSelectedFile}>
                {sortedFiles.map((file) => (
                    <Radio value={file} key={file}>{file}</Radio>
                ))}
            </Radio.Group>
        </div>
    );
}

export default ServerFileSelector;