import React, {useState} from 'react';
import {TimePicker, Slider, Row, Col, Typography} from 'antd';
import dayjs from 'dayjs';

const {Text} = Typography;

const TimeSelector = ({timeRange, setTimeRange, sliderValue, setSliderValue,endTime}) => {
    const format = 'HH:mm:ss';
    const endTimeDayjs = dayjs(endTime, format);

    const disabledHours = () => {
        const hours = [];
        for (let i = endTimeDayjs.hour() + 1; i < 24; i++) {
            hours.push(i);
        }
        return hours;
    };

    const disabledMinutes = (selectedHour) => {
        const minutes = [];
        if (selectedHour === endTimeDayjs.hour()) {
            for (let i = endTimeDayjs.minute() + 1; i < 60; i++) {
                minutes.push(i);
            }
        }
        return minutes;
    };

    const disabledSeconds = (selectedHour, selectedMinute) => {
        const seconds = [];
        if (selectedHour === endTimeDayjs.hour() && selectedMinute === endTimeDayjs.minute()) {
            for (let i = endTimeDayjs.second() + 1; i < 60; i++) {
                seconds.push(i);
            }
        }
        return seconds;
    };

    const onTimeRangeChange = (values) => {
        setTimeRange(values);
        if (values[0] && values[1]) {
            const startSeconds = values[0].hour() * 3600 + values[0].minute() * 60 + values[0].second();
            const endSeconds = values[1].hour() * 3600 + values[1].minute() * 60 + values[1].second();
            setSliderValue([startSeconds, endSeconds]);
        }
    };

    const onSliderChange = (value) => {
        setSliderValue(value);
        const newStartTime = dayjs().startOf('day').add(value[0], 'second').format(format);
        const newEndTime = dayjs().startOf('day').add(value[1], 'second').format(format);
        setTimeRange([dayjs(newStartTime, format), dayjs(newEndTime, format)]);
    };

    return (
        <>
            <Row align="middle" gutter={16}>
                <Col>
                    <Text>Start Time:</Text>
                </Col>
                <Col>
                    <TimePicker
                        showNow={false}
                        inputReadOnly
                        hideDisabledOptions
                        value={timeRange[0]}
                        onChange={(time) => onTimeRangeChange([time, timeRange[1]])}
                        format={format}
                        disabledHours={disabledHours}
                        disabledMinutes={disabledMinutes}
                        disabledSeconds={disabledSeconds}
                    />
                </Col>
                <Col>
                    <Text>End Time:</Text>
                </Col>
                <Col>
                    <TimePicker
                        showNow={false}
                        inputReadOnly
                        hideDisabledOptions
                        value={timeRange[1]}
                        onChange={(time) => onTimeRangeChange([timeRange[0], time])}
                        format={format}
                        disabledHours={disabledHours}
                        disabledMinutes={disabledMinutes}
                        disabledSeconds={disabledSeconds}
                    />
                </Col>
            </Row>
            <Slider
                range
                min={0}
                max={endTimeDayjs.diff(dayjs().startOf('day'), 'second')}
                value={sliderValue}
                onChange={onSliderChange}
            />
        </>
    );
};

export default TimeSelector;