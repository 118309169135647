import React from 'react';
import ArtplayerFLV from "./ArtPlayerContainer_FLV";
import ArtPlayerMP4 from "./ArtPlayerContainer_MP4";

const FLVPlayer = ({url,setEndTime}) => {
    return (
        <>
            <div>
                <ArtplayerFLV
                    option={{
                        url: url,
                    }}
                    style={{
                        width: '100%',
                        height: '300px',
                    }}
                    setEndTime={setEndTime}
                    getInstance={(art) => console.info(art)}
                />
            </div>
        </>
    );
};

const MP4Player = ({url}) => {
    return (
        <div>
            <ArtPlayerMP4
                option={{
                    url: url,
                }}
                style={{
                    width: '100%',
                    height: '300px',
                }}
                getInstance={(art) => console.info(art)}
            />
        </div>
    );
}

export {FLVPlayer,MP4Player};