import {Button, Divider, Form, Modal, Input, Tabs, message} from "antd";
import React from "react";
import axios from "axios";
import cookie from 'react-cookies'
import {BASE_URL} from "../../../../utils/url";
import {useSelector} from "react-redux";
import useLoading from "../../../../features/hooks/useLoading";

const UploadToNeteaseCloud = () => {
    const [isLoginModalOpen, setIsLoginModalOpen] = React.useState(false);
    const { loadings, enterLoading, exitLoading } = useLoading(1);
    const handleOk_LoginModal = () => {
        setIsLoginModalOpen(false);
    };
    const handleCancel_LoginModal = () => {
        setIsLoginModalOpen(false);
    };
    const requestLogin = () => {
        console.log('login')
        setIsLoginModalOpen(true);

    }
    const onCookieLoginFinish = (values) => {
        console.log('cookie:', values.cookie)
        cookie.save('MUSIC_U', values.cookie, {path: '/'})
    }
    const checkLogin = async () => {
        console.log(cookie.load('MUSIC_U'))
        try {
            const response = await axios.get('https://1253612033-75hf3il7hx.ap-guangzhou.tencentscf.com/login/status?cookie=MUSIC_U%3D' + cookie.load('MUSIC_U'));
            console.log(response.data.data.account);
            if (response.data.data.account !== null) {
                message.success('已登录')
            } else {
                message.warning('未登录')
            }
        } catch (e) {
            console.log(e)
        }
    }
    const download_link = useSelector(state => state.downloadUrl.downloadUrl)
    const uploadToCloud = async () => {
        const urlRegex = /^(https?:\/\/)?(www\.)?(bilibili\.com\/video|b23\.tv)/;
        if (urlRegex.test(download_link)) {
            try {
                enterLoading(0);
                const response = await axios.post(
                    BASE_URL + '/api/upload-to-netease/',
                    {
                        userInput: download_link,
                        cookie: cookie.load('MUSIC_U')
                    }
                )
                const downloadId = response.headers['x-download-id'];
                await axios.post(BASE_URL + '/api/complete-download/', {downloadId});
                message.success('上传成功！')
            } catch (e) {
                console.error('上传失败：', e);
                message.error(e)
            } finally {
                exitLoading(0);
            }
        } else {
            message.error('请输入正确的链接');
        }
    }

    const uploadToPodcast = () => {
        message.warning('开发中')
    }

    return (<>
        <Button style={{marginRight: '5px'}} onClick={requestLogin} type={"primary"}>
            登录
        </Button>
        <Modal
            open={isLoginModalOpen}
            onCancel={handleCancel_LoginModal}
            footer={null}
        >
            <Tabs defaultActiveKey="cookie">
                <Tabs.TabPane tab="cookie登录" key="cookie">
                    <Form
                        style={{marginTop: '25px'}}
                        name="cookie"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onCookieLoginFinish}
                        // onFinishFailed={onCookieLoginFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="cookie"
                            name="cookie"
                            rules={[{
                                required: true, message: '请填写cookie',
                            },]}
                        >
                            <Input
                                placeholder={'MUSIC_U='}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                onClick={handleOk_LoginModal}
                            >
                                登录
                            </Button>
                        </Form.Item>
                    </Form>
                </Tabs.TabPane>
            </Tabs>
        </Modal>
        <Button
            style={{marginRight: '10px'}}
            onClick={checkLogin}
            type="primary"
        >
            检查登录状态
        </Button>
        <Divider/>
        <Button onClick={uploadToCloud} loading={loadings[0]} type="primary">上传到云盘</Button>
        <Button style={{marginLeft:'5px'}} onClick={uploadToPodcast} type="primary">上传到播客</Button>
    </>)
}
export default UploadToNeteaseCloud;
