import { useState, useCallback } from 'react';

function useLoading(initialCount = 0) {
    const [loadings, setLoadings] = useState(Array(initialCount).fill(false));

    const enterLoading = useCallback((index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = true;
            return newLoadings;
        });
    }, []);

    const exitLoading = useCallback((index) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = false;
            return newLoadings;
        });
    }, []);

    return { loadings, enterLoading, exitLoading };
}

export default useLoading;