import {Card, Divider, Space} from 'antd';
import React from 'react';
import Pic from '../../assets/index_pc.png';
import {GithubOutlined} from "@ant-design/icons";

const {Meta} = Card;

const Home = () => {
    return (
        <>
            <div style={{marginLeft: '10px'}}>
                <h2>你好</h2>
            </div>
            <Divider/>
            <Card
                title="关于"
                size={"small"}
                style={{
                    maxWidth: 350
                }}
            >
                <Space direction={'horizontal'}>
                    <GithubOutlined/>
                    <span>Github：github.com/wodexiaobai322/liveDivide</span>
                </Space>
            </Card>
            <Card
                hoverable
                style={{
                    marginTop: '10px',
                    maxWidth: 350,
                }}
                cover={<img alt="" src={Pic}/>}
            >
                <Meta title="关注雨纪_Ameki" description="live.bilibili.com/27628019"/>
            </Card>
        </>
    );
};

export default Home;