import {createSlice} from "@reduxjs/toolkit";

const urlSlice = createSlice({
    name: "url-slice",
    initialState: {
        downloadUrl: "",
    },
    reducers: {
        setDownloadUrl: (state, action) => {
            state.downloadUrl = action.payload;
        },
    },
})

export default urlSlice;